@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color:white;
  background-color: #121418;
}

/* #root {

  min-height: 100vh;

  box-shadow: 0px 10px 93px 0px rgba(0,0,0,0.4);
  -webkit-box-shadow: 0px 10px 93px 0px rgba(0,0,0,0.4);
  -moz-box-shadow: 0px 10px 93px 0px rgba(0,0,0,0.4);
  margin-top:80px;
  padding-top:24px;

  overflow:hidden;
  border-radius:48px 48px 0px 0px;
  box-sizing: border-box!important;
}  */

/* @media (min-width: 1600px) {
  #root {
    max-width: 1600px!important;
    background-color: #23272f;
    position:relative;
    left:50%;
    transform: translateX(-50%);
    
    height:100vh;
    overflow-x:hidden;
    overflow-y:scroll;
    margin-top:48px;
    padding-top:8px;
    border-radius:48px 48px 0px 0px;
  }
} */

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
.cs-main-container{
  border: none!important;
  background-color:#23272f!important;
  /* width: 800px!important; */
  width: "90%"!important;
}

@media (min-width: 900px) { /* Apply styles for md and larger */
  .cs-main-container {
    width: 800px!important;
  }
}

.cs-message-list{
  background-color:#23272f!important;

}

.cs-message-list:after {
  content: "";
  position: absolute;
  z-index: 1;
  bottom: 0;
  left: 0;
  pointer-events: none;
  background-image: linear-gradient(to bottom, rgba(255,255,255,0), #23272f 90%);
  width: 100%;
  height: 8px;
}

.cs-message__content{
  border-radius: .7em .7em .7em .7em!important;
  font-weight: normal;
  font-family: unset!important;
  font-size: unset!important;
}

/* .scrollbar-container {
  padding-left: 128px!important;
  padding-right: 128px!important; 
  padding-top: 32px!important;
} */

.cs-message--incoming .cs-message__content{
  margin-top:16px;
  background-color: #16181d!important;
  color:white!important;
}

.cs-message--outgoing .cs-message__content{
  margin-top:16px;
  background-color: #58c4dc!important;
  color: black!important;
}

/*TODO xs margin on messages*/

.ps__rail-y {
  background-color: transparent!important;
}

.ps__thumb-y {
  background-color:rgba(0,0,0,0.5)!important;
}

.cs-message-list .cs-typing-indicator{
  background-color:#23272f!important;
}

.cs-typing-indicator__dot {
  background-color:#58c4dc!important;
}

.cs-typing-indicator__text {
  color:#58c4dc!important;
}

.MuiMenu-list {
  background-color:#23272f!important;
}

a{
  color:#58c4dc!important;
  text-decoration: none;
}

.ripple-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  pointer-events: none;
  z-index: 9999;
}


.pointdown {
  animation: upDown 2s infinite;
}

@keyframes upDown {
  0%, 100% {
      transform: translateY(0);
  }
  50% {
      transform: translateY(-10px);
  }
}

.Spreadsheet--dark-mode{
  --background-color: "none"!important;
}

.data-table{
  background-color:"#23272f"!important;
}

::-webkit-scrollbar {
  width: 2px;
  height: 2px;
}
::-webkit-scrollbar-button {
  width: 0px;
  height: 0px;
}
::-webkit-scrollbar-thumb {
  background: #333a45;
  border: 0px none #ffffff;
  border-radius: 50px;
}
::-webkit-scrollbar-thumb:hover {
  background: #ffffff;
}
::-webkit-scrollbar-thumb:active {
  background: #000000;
}
::-webkit-scrollbar-track {
  background: #16181d;
  border: 0px none #ffffff;
  border-radius: 50px;
}
::-webkit-scrollbar-track:hover {
  background: #16181d;
}
::-webkit-scrollbar-track:active {
  background: #333333;
}
::-webkit-scrollbar-corner {
  background: transparent;
}
